import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import{PmsUserObj} from '../../interfaces/db/pms-user';

@Injectable({
  providedIn: 'root'
})
export class PmsUserService {
  private addUserUrl='/api/pmsUser/addUser';
  private getUsersUrl='/api/pmsUser/getPmsUsers';

  constructor(private http:HttpClient) { }

  public add(param: any): Observable<any> {
    let body = JSON.stringify(param);
    const headers = new HttpHeaders().set('content-type', 'application/json');
        return this.http
            .post<any>(this.addUserUrl, body,{headers});
    } 
  public getUsers(departmentId:number): Observable<PmsUserObj[]> {
    //console.log('calling get users in pms-user.service');

  const params=new HttpParams().set('departmentId',departmentId.toString());
      return this.http.get<PmsUserObj[]>(this.getUsersUrl,{params});
    }
}
